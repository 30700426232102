import "./App.scss";
import React, { useState, useRef, useEffect, useCallback } from "react";
import AdobeSVG from "./svgs/photoshop.png";
import LinkedSVG from "./svgs/Linkedin";
import GIMPSVG from "./svgs/gimp png.png";
import FiverrSVG from "./svgs/Fiverr";
import FigmaSVG from "./svgs/FigmaSVG";
import DribbleSVG from "./svgs/Dribble";
import phoneOne from "./svgs/phone1.png";
import phoneTwo from "./svgs/phone2.png";
import macOne from "./svgs/mac1.png";
import macTwo from "./svgs/mac2.png";
import Projects from "./projects/Projects";
import useWidth from "./hooks/useWidth";
import axios from "axios";
import FigmaSVGFront from "./svgs/FigmaSVGFront";
import TinderCard from "react-tinder-card";
import Simple from "./components/card";

function App() {
  const width = useWidth();
  const [showDash, setShowDash] = useState(false);
  const dribbbleLink = "https://dribbble.com/AlekS_s";
  const linkedinLink = "https://www.linkedin.com/in/aleksandra-smith/";
  const figmaLink =
    "https://www.figma.com/proto/xgDKzbLniHfDcZcCBUtjh5/Portfolio?page-id=0%3A1&type=design&node-id=1-8757&viewport=987%2C226%2C0.26&t=yjGt7CqdoEFRuUTD-1&scaling=min-zoom&starting-point-node-id=1%3A8757&mode=design`"
  const maxLengthOfContent = 500;
  const validateName = useRef();
  const validateEmail = useRef();

  const [isDisabled1, setIsDisabled1] = useState(false);
  const [isDisabled2, setIsDisabled2] = useState(true);
  const [inputInfo, setInputInfo] = useState({
    yourName: "",
    yourEmail: "",
  });
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [emailContent, setEmailContent] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let isValid = true;

    if (name === "yourName") {
      const lettersOnly = /^[A-Za-z ]+$/;
      isValid = value.match(lettersOnly);
      setNameErr(!isValid);
    }

    if (name === "yourEmail") {
      const validEmailAddress = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      isValid = value.match(validEmailAddress);
      setEmailErr(!isValid);
    }

    setInputInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("./php/sendEmail.php", { inputInfo, emailContent })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setError(!error);
        setEmailErr(false);
        setNameErr(false);
      });
  };
  const handleEmailBlur = () => {
    const validEmailAddress = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (validateEmail.current.value.match(validEmailAddress)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };
  const handleName = () => {
    const lettersOnly = /^[A-Za-z ]+$/;
    if (validateName.current.value.match(lettersOnly)) {
      setNameErr(false);
    } else {
      setNameErr(true);
    }
  };
  const handleEmailContent = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxLengthOfContent) {
      setEmailContent(inputValue);
    }
  };
  const handleOpenDash = () => {
    setShowDash(!showDash);
  };

  useEffect(() => {
    if (inputInfo.yourName !== "" && !nameErr) {
      setIsDisabled2(false);
    }
    if (inputInfo.yourEmail !== "" && !emailErr) {
      setIsDisabled1(false);
    }
    if (nameErr) {
      setIsDisabled2(true);
    }
    if (emailErr) {
      setIsDisabled1(true);
      setIsDisabled2(false);
    }
  }, [inputInfo.yourName, emailErr]);

  const handleTextClick = () => {
    if (!emailErr || !nameErr) {
      setIsDisabled1(false);
      setIsDisabled2(false);
    }
  };

  const handleEmailClick = () => {
    setEmailErr(false);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [changeTextTwo, setChangeTextTwo] = useState("");
  const [currentWord, setCurrentWord] = useState("I design simple");
  const [nextWord, setNextWord] = useState("intuitive products");
  const [indexOfLetters, setIndexOfLetters] = useState(0);
  const [indexOfLettersTwo, setIndexOfLettersTwo] = useState(0);
  const [speed, setSpeed] = useState(100);

  const [directionTinder, setDirectionTinder] = useState(
    "swipeCornerDownRight"
  );

  useEffect(() => {
    let interval;

    const showNextLetter = () => {
      if (indexOfLetters < currentWord.length) {
        setChangeText((prevText) => prevText + currentWord[indexOfLetters]);
        setIndexOfLetters((prevIndex) => prevIndex + 1);
      } else if (
        indexOfLetters >= currentWord.length &&
        indexOfLettersTwo < nextWord.length
      ) {
        setChangeTextTwo((prevText) => prevText + nextWord[indexOfLettersTwo]);
        setIndexOfLettersTwo((prevIndex) => prevIndex + 1);
      }
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      // Check if the scroll position is within the first 80 vh
      if (scrollPosition >= 300) {
        setIsVisible(true);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clear existing interval
    clearInterval(interval);

    // Set a new interval with the updated speed
    interval = setInterval(() => {
      if (isVisible) {
        setSpeed(100);
        showNextLetter();
      }
    }, speed);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(interval);
    };
  }, [isVisible, indexOfLetters, indexOfLettersTwo, currentWord, nextWord]);

  const onSwipe = (direction) => {
    console.log("You swiped: " + direction);
  };

  const onCardLeftScreen = (myIdentifier) => {
    console.log(myIdentifier + " left the screen");
  };

  return (
    <div className={`site-container ${!success ? "" : "reduced"}`}>
      {showDash && (
        <>
          <p
            className="exitButtonMain"
            onClick={handleOpenDash}
            onTouchStart={handleOpenDash}
          >
            home
          </p>
          <Projects />
        </>
      )}
      <div className="site-wrapper">
        <div className="Heading">I'm Aleks</div>

        <div className="Subheading">
          a user experience designer<span className="Dot">.</span>
        </div>

        <div className="SubheadingMobile">
          a ux<span className="Dot">'</span>er
        </div>

        <div className="ArtCircleTop" />

        <div className="Headline">
          {changeText}
          <span className="Headline2">{changeTextTwo}</span>
        </div>

        <div className="ArtCircleBottom" />
        <div className="Projects">
          how?
          <div class="arrow-container" />
        </div>

        <div className="Projects-Container">
          <Simple />
        </div>

        <div onClick={handleOpenDash} className="Button">
          See all projects
        </div>
        {!error ? (
          <div className="Contact">{!success ? "Contact" : "Contacted"}</div>
        ) : (
          <div className="Contact">
            <span>That didn't work</span>
            <span className="Contact-Reach">
              Reach me at{" "}
              <span
                onClick={() =>
                  window.open("mailto:aleksandrasygasmith@gmail.com")
                }
              >
                aleksandrasygasmith@gmail.com
              </span>
            </span>
          </div>
        )}

        {!success ? (
          <div className="Contact-Form-Wrapper">
            <input
              className="Form-Name"
              placeholder="Name"
              type="text"
              id="yourName"
              name="yourName"
              onChange={handleInputChange}
              maxLength={20}
              onBlur={handleName}
              ref={validateName}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onClick={() => setNameErr(false)}
              disabled={isDisabled1}
            />
            <input
              placeholder="Email"
              className="Form-Email"
              id="yourEmail"
              type="email"
              name="yourEmail"
              onChange={handleInputChange}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onBlur={handleEmailBlur}
              ref={validateEmail}
              onClick={handleEmailClick}
              disabled={isDisabled2}
            />

            <textarea
              id="emailTextarea"
              value={emailContent}
              className="Form-Input"
              onChange={handleEmailContent}
              maxLength={maxLengthOfContent}
              onClick={handleTextClick}
            />

            <button className="submitButton" onClick={submitEmail}>
              send
            </button>
            <div className="line"></div>

            <div className="Form-Error-Messsages">
              {nameErr && (
                <div className="errorPopUpN">
                  <p>
                    Not even Elon got away with that...
                    <br />
                    letters only please
                  </p>
                </div>
              )}
              {emailErr && (
                <div className="errorPopUpE">
                  <p>Almost...</p>
                </div>
              )}
            </div>
          </div>
        ) : null}

        <div className="socials">
          <div className="Linkedin">
            {width < 480 ? (
              <LinkedSVG width={50} height={50} />
            ) : (
              <LinkedSVG width={60} height={60} />
            )}
            <a href={linkedinLink} target="_blank" rel="noreferrer">
              LinkedIn
            </a>
          </div>
          <div className="Dribbble">
            {width < 480 ? (
              <DribbleSVG width={50} height={50} />
            ) : (
              <DribbleSVG width={60} height={60} />
            )}
            <a href={dribbbleLink} target="_blank" rel="noreferrer">
              Dribbble
            </a>
          </div>
          <div className="Fiverr">
            <span>
              {width < 480 ? (
                <FigmaSVGFront width={45} height={45} />
              ) : (
                <FigmaSVGFront width={55} height={55} />
              )}
            </span>
            <a href={figmaLink} target="_blank" rel="noreferrer">
              Portfolio
            </a>
          </div>
        </div>
      </div>
      <hr className="ArtLineFooter" />
    </div>
  );
}

export default App;
