import React, { useState } from "react";
import "./projects.scss";
import figmaWidgetsData from "../data/figmaWidgetData.json";
import sandBoxWidgetsData from "../data/sandboxWidgetData.json";
import useWidth from "../hooks/useWidth";

const Projects = () => {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [filter, setFilter] = useState("All");
  const [figmaWidgets, setFigmaWidgets] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  const [figmaFolio, setFigmaFolio] = useState(false);

  const screenWidth = useWidth();

  const handleWidgetMouseEnter = (image) => {
    setHoveredImage(image);
    setIsHovered(true);
  };

  const handleWidgetMouseLeave = () => {
    setHoveredImage(null);
    setIsHovered(false);
  };

  const handleFigmaFrame = () => {
    setIframeSrc(
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FxgDKzbLniHfDcZcCBUtjh5%2FPortfolio%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-8757%26viewport%3D987%252C226%252C0.26%26t%3DMIb221sWhbl957B1-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D1%253A8757%26mode%3Ddesign"
    );
    setShowIframe(true);
  };

  const handleFrame = (link) => {
    setIframeSrc(link);
    setShowIframe(true);
  };

  const handleNewTab = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const backgroundurls = `url(img/widgetImages/${hoveredImage})`;

  return (
    <div className="project-dashboard">
      {showIframe && (
        <div className="iframeCinema">
          <div className="iframeWrapper">
            <p
              className="exitButton"
              onClick={() => {
                setShowIframe(false);
                setFigmaFolio(false);
              }}
              onTouchStart={() => {
                setShowIframe(false);
                setFigmaFolio(false);
              }}
            >
              back
            </p>
            <p>loading...</p>
            <iframe
              title="Embedded Content"
              src={iframeSrc}
              width="100%"
              height="100%"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </div>
      )}
      <div className="side-bar">
        <div className="side-bar-items-wrapper">
          <div className="side-bar-items">
            <div
              className={`icon-wrapper ${
                figmaWidgets && !figmaFolio
                  ? "active"
                  : !figmaFolio && !figmaWidgets
                  ? ""
                  : null
              }`}
            >
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaWidgets(true);
                  setFigmaFolio(false);
                }}
              >
                Coding practice
              </div>
            </div>
            <div
              className={`icon-wrapper ${
                figmaWidgets && !figmaFolio
                  ? ""
                  : figmaFolio && figmaWidgets
                  ? ""
                  : "active"
              }`}
            >
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaWidgets(false);
                  setFigmaFolio(false);
                }}
              >
                Figma projects
              </div>
            </div>
            <div className={`icon-wrapper ${!figmaFolio ? "" : "active"}`}>
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaFolio(true);
                  handleFigmaFrame();
                }}
              >
                Figma portfolio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="widget-screen-container"
        style={{
          backgroundImage: hoveredImage ? backgroundurls : backgroundurls,
          transition: "background-image 1s ease-in-out",
        }}
      >
        <div className="filterWrapper">
          {screenWidth > 768 ? (
            <div
              className={`allCards ${filter === "All" ? "active" : ""}`}
              onClick={() => setFilter("All")}
            >
              All
            </div>
          ) : (
            <div
              className={`allCards ${
                filter === "All" && !figmaWidgets
                  ? "mobileActive"
                  : "mobileInactive"
              }`}
              onClick={() => {
                setFilter("All");
                setFigmaWidgets(false);
              }}
            >
              Figma
            </div>
          )}

          {screenWidth > 768 ? (
            <div
              className={`filterComm ${
                filter === "Commercial" ? "active" : ""
              }`}
              onClick={() => setFilter("Commercial")}
            >
              Professional
            </div>
          ) : (
            <div
              className={`filterComm ${
                filter === "All" && figmaWidgets
                  ? "mobileActive"
                  : "mobileInactive"
              }`}
              onClick={() => {
                setFilter("All");
                setFigmaWidgets(true);
              }}
            >
              Coding
            </div>
          )}
          {screenWidth > 768 ? (
            <div
              className={`filterConcept ${
                filter === "Concepts" ? "active" : ""
              }`}
              onClick={() => setFilter("Concepts")}
            >
              Spec work
            </div>
          ) : (
            <div
              className="filterFolioCard"
              onClick={() => {
                setFigmaFolio(true);
                handleFigmaFrame();
              }}
            >
              Full Folio
            </div>
          )}
        </div>

        <div className="widget-screen-opacity" />
        <div className="widget-screen">
          {figmaWidgets
            ? sandBoxWidgetsData.map((widget, index) => {
                if (
                  (filter === "Commercial" && widget.type === "commercial") ||
                  (filter === "Concepts" && widget.type === "concept") ||
                  filter === "All"
                ) {
                  return (
                    <div
                      className="project-widget-sandBox"
                      onMouseEnter={() => handleWidgetMouseEnter(widget.image)}
                      onTouchStart={() => handleWidgetMouseEnter(widget.image)}
                      onMouseLeave={() => handleWidgetMouseLeave(null)}
                      key={index}
                      style={{
                        transform:
                          hoveredImage && hoveredImage !== widget.image
                            ? "none"
                            : "scale(1.3)",
                        transformOrigin: "center",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    >
                      <div className="widget-body">
                        {hoveredImage === widget.image ? null : (
                          <div className="widget-left">
                            <img
                              className="widget-image"
                              src={require(`../assets/widgetImages/${widget.image}`)}
                              alt="project landing image"
                            />
                          </div>
                        )}
                        <div
                          className={`widget-right ${
                            isHovered && hoveredImage === widget.image
                              ? "fullWidgetRight"
                              : ""
                          }`}
                        >
                          <div
                            className={`widget-heading ${
                              isHovered && hoveredImage === widget.image
                                ? "fullWidgetHeading"
                                : ""
                            }`}
                          >
                            {widget.heading}
                          </div>
                          {isHovered && hoveredImage === widget.image ? (
                            <div
                              className={`widget-description ${
                                isHovered && hoveredImage === widget.image
                                  ? "fullWidgetSubHeading"
                                  : ""
                              }`}
                            >
                              {widget.content}
                            </div>
                          ) : null}
                          {isHovered && hoveredImage === widget.image ? (
                            <>
                              <div
                                className="widget-open-iframe"
                                onClick={() => handleFrame(widget.link)}
                              >
                                Open in-app
                              </div>
                              <div
                                className="widget-open-ext"
                                onClick={() => handleNewTab(widget.link)}
                              >
                                Open new tab
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // Don't render this widget
              })
            : figmaWidgetsData.map((widget, index) => {
                if (
                  (filter === "Commercial" && widget.type === "commercial") ||
                  (filter === "Concepts" && widget.type === "concept") ||
                  filter === "All"
                ) {
                  return (
                    <div
                      className="project-widget"
                      onMouseEnter={() => handleWidgetMouseEnter(widget.image)}
                      onTouchStart={() => handleWidgetMouseEnter(widget.image)}
                      onMouseLeave={() => handleWidgetMouseLeave(null)}
                      key={index}
                      // ref={widget.image === triggeredWidget?.image ? targetRef : null}
                      // data-widget-image={widget.image}
                      style={{
                        transform:
                          hoveredImage && hoveredImage !== widget.image
                            ? "none"
                            : "scale(1.3)",
                        transformOrigin: "center",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    >
                      <div className="widget-body">
                        {hoveredImage === widget.image ? null : (
                          <div className="widget-left">
                            <img
                              className="widget-image"
                              src={require(`../assets/widgetImages/${widget.image}`)}
                              alt=""
                            />
                          </div>
                        )}
                        <div
                          className={`widget-right ${
                            isHovered && hoveredImage === widget.image
                              ? "fullWidgetRight"
                              : ""
                          }`}
                        >
                          <div
                            className={`widget-heading ${
                              isHovered && hoveredImage === widget.image
                                ? "fullWidgetHeading"
                                : ""
                            }`}
                          >
                            {widget.heading}
                          </div>
                          {isHovered && hoveredImage === widget.image ? (
                            <div
                              className={`widget-description ${
                                isHovered && hoveredImage === widget.image
                                  ? "fullWidgetSubHeading"
                                  : ""
                              }`}
                            >
                              {widget.content}
                            </div>
                          ) : null}
                          {isHovered && hoveredImage === widget.image ? (
                            <>
                              <div
                                className="widget-open-iframe"
                                onClick={() => {
                                  screenWidth > 768
                                    ? handleFrame(widget.link)
                                    : handleFrame(widget.mobileLink);
                                }}
                              >
                                Open in-app
                              </div>
                              <div
                                className="widget-open-ext"
                                onClick={() => {
                                  screenWidth > 768
                                    ? handleNewTab(widget.link)
                                    : handleNewTab(widget.mobileLink);
                                }}
                              >
                                Open new tab
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // Don't render this widget
              })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
